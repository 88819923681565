import { convertNullIfEmpty as convNull } from "@/utils/null-check";

class subsalesOTPModel {
    static putPayload(e) {
        for (const key in e) {
            e[key] = convNull(e[key]);
        }

        return {
            purchasePriceNum: e?.purchasePriceNum || "0.00",
            propertyAddress: e?.propertyAddress || "",
            additionalInclude: e?.additionalInclude || "",
            depositRate1: e?.depositRate1 || "0.00",
            depositAmount1: e?.depositAmount1 || "0.00",
            depositRate2: e?.depositRate2 || "0.00",
            depositAmount2: e?.depositAmount2 || "0.00",
            balancePaidWithinDays1: parseInt(e?.balancePaidWithinDays1) || 0,
            balancePaidWithinDays2: parseInt(e?.balancePaidWithinDays2) || 0,
            gracePeriod: parseInt(e?.gracePeriod) || 0,
            interestRate: e?.interestRate || "0.00",
            spaExecutionDays1: parseInt(e?.spaExecutionDays1) || 0,
            spaExecutionDays2: parseInt(e?.spaExecutionDays2) || 0,
            specialCondition: e?.specialCondition || "",
            paymentAmount: e?.paymentAmount || "0.00",
            vendorName: e?.vendorName || "",
            otpValidDays: parseInt(e?.otpValidDays) || 0
        };
    }

    static getToEdit(e) {
        return {
            purchasePriceNum: e?.purchasePriceNum || "",
            propertyAddress: convNull(e?.propertyAddress) || "",
            additionalInclude: convNull(e?.additionalInclude) || "",
            depositRate1: e?.depositRate1 || "",
            depositAmount1: e?.depositAmount1 || "",
            depositRate2: e?.depositRate2 || "",
            depositAmount2: e?.depositAmount2 || "",
            balancePaidWithinDays1: convNull(e?.balancePaidWithinDays1) || "",
            balancePaidWithinDays2: convNull(e?.balancePaidWithinDays2) || "",
            gracePeriod: e?.gracePeriod || "",
            interestRate: e?.interestRate || "",
            spaExecutionDays1: convNull(e?.spaExecutionDays1) || "",
            spaExecutionDays2: convNull(e?.spaExecutionDays2) || "",
            specialCondition: convNull(e?.specialCondition) || "",
            paymentAmount: convNull(e?.paymentAmount) || "",
            vendorName: convNull(e?.vendorName) || "",
            otpValidDays: convNull(e?.otpValidDays) || ""
        };
    }
}

export default subsalesOTPModel;
