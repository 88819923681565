// import { convertNullIfEmpty } from "@/utils/null-check";

export default class ProjectBankLoanModel {
    static putDeclinePayload(e) {
        return {
            description: e.description
        };
    }
    static putRejectPayload(e) {
        return {
            description: e.description
        };
    }
    static putApproveLAPayload(e) {
        return {
            description: e.description,
            finalLoanAmount: e.finalLoanAmount.toString()
        };
    }
}
