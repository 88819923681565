import { convertNullIfEmpty as convNull } from "@/utils/null-check";
class rentOTRModel {
    static putPayload(e) {
        for (const key in e) {
            e[key] = convNull(e[key]);
        }

        return {
            propertyAddress: e?.propertyAddress || "",
            propertyType: e?.propertyType || "",
            landlordAddress: e?.landlordAddress || "",
            tenantName: e?.tenantName || "",
            tenantAddress: e?.tenantAddress || "",
            rentPrice: convNull(e?.rentPrice?.toString()) || "0.00",
            billInclusive: e?.billInclusive || "",
            rentInAdvance: convNull(e?.rentInAdvance?.toString()) || "0.00",
            securityDeposit: convNull(e?.securityDeposit?.toString()) || "0.00",
            utilitiesDeposit:
                convNull(e?.utilitiesDeposit?.toString()) || "0.00",
            otherDeposit: convNull(e?.otherDeposit?.toString()) || "0.00",
            subtotal: convNull(e?.subtotal?.toString()) || "0.00",
            earnestDeposit: convNull(e?.earnestDeposit?.toString()) || "0.00",
            balanceToPay: convNull(e?.balanceToPay?.toString()) || "0.00",
            remark: e?.remark || "",
            commencementDate: e?.commencementDate || "",
            rentFreePeriodFrom: e?.rentFreePeriodFrom || "",
            rentFreePeriodTo: e?.rentFreePeriodTo || "",
            tenancyTerms: e?.tenancyTerms || "",
            renewTerm: e?.renewTerm || "",
            renewFurtherTerm: e?.renewFurtherTerm || "",
            totalDisbursement:
                convNull(e?.totalDisbursement?.toString()) || "0.00",
            borneBy: e?.borneBy || "",
            parkingLotUnit: e?.parkingLotUnit || "",
            propertyCondition: e?.propertyCondition || "",
            viewingDate: e?.viewingDate || "",
            otherCondition: e?.otherCondition || "",
            workingDayTA: e?.workingDayTA || "",
            payeeName: e?.payeeName || "",
            workingDayOTR: e?.workingDayOTR || ""
        };
    }

    static getToEdit(e) {
        return {
            propertyAddress: convNull(e?.propertyAddress) || "",
            propertyType: convNull(e?.propertyType) || "",
            landlordAddress: convNull(e?.landlordAddress) || "",
            tenantName: convNull(e?.tenantName) || "",
            tenantAddress: convNull(e?.tenantAddress) || "",
            rentPrice: convNull(e?.rentPrice) || "",
            billInclusive: convNull(e?.billInclusive) || "",
            rentInAdvance: convNull(e?.rentInAdvance) || "",
            securityDeposit: convNull(e?.securityDeposit) || "",
            utilitiesDeposit: convNull(e?.utilitiesDeposit) || "",
            otherDeposit: convNull(e?.otherDeposit) || "",
            subtotal: convNull(e?.subtotal) || "",
            earnestDeposit: convNull(e?.earnestDeposit) || "",
            balanceToPay: convNull(e?.balanceToPay) || "",
            remark: convNull(e?.remark) || "",
            commencementDate: convNull(e?.commencementDate) || "",
            rentFreePeriodFrom: convNull(e?.rentFreePeriodFrom) || "",
            rentFreePeriodTo: convNull(e?.rentFreePeriodTo) || "",
            tenancyTerms: convNull(e?.tenancyTerms) || "",
            renewTerm: convNull(e?.renewTerm) || "",
            renewFurtherTerm: convNull(e?.renewFurtherTerm) || "",
            totalDisbursement: convNull(e?.totalDisbursement) || "",
            borneBy: convNull(e?.borneBy) || "",
            parkingLotUnit: convNull(e?.parkingLotUnit) || "",
            propertyCondition: convNull(e?.propertyCondition) || "",
            viewingDate: convNull(e?.viewingDate) || "",
            otherCondition: convNull(e?.otherCondition) || "",
            workingDayTA: convNull(e?.workingDayTA) || "",
            payeeName: convNull(e?.payeeName) || "",
            workingDayOTR: convNull(e?.workingDayOTR) || ""
        };
    }
}

export default rentOTRModel;
