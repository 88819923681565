import { convertNullIfEmpty as convNull } from "@/utils/null-check";
import { getFullAddress } from "@/utils/string";
import cloneDeep from "lodash/cloneDeep";
import subsalesOTPModel from "@/models/subsalesOTP";
import rentOTRModel from "@/models/rentOTR";

class PropertyModel {
    static relativePropertyPayload(e) {
        return {
            "purpose[partial]": e.purpose,
            "title[partial]": e.title,
            "agencyUser:id": e.agencyUser,
            "country:id": e.countryId,
            "state:id": e.stateId,
            "city:id": e.cityId,
            "area:id": e.areaId,
            "propertyCategory[in]": e.propertyCategory,
            "propertyType:id[in]": e.propertyType,
            "furnishingType[in]": e.furnishingType,
            allowPartnership: e.allowPartnership,
            numberOfBedroom: e.numberOfBedroom,
            numberOfBathroom: e.numberOfBathroom
        };
    }

    static postPayload(param) {
        let e = cloneDeep(param);
        return {
            countryId: e.countryId || "",
            stateId: e.stateId || "",
            cityId: e.cityId || "",
            areaId: e.areaId || "",
            street: e.street || "",
            buildingId: e.buildingId || "",
            lotNumber: e.lotNumber || "",
            title: e.title || "",
            propertyCategory: e.propertyCategory || "",
            propertyTypeId: e.propertyTypeId || "",
            lotTypeId: e.lotTypeId || "",
            tenureType: e.tenureType || [],
            furnishingType: e.furnishingType || "",
            purpose: e.purpose || "",
            areaUnitType: e.areaUnitType || "",
            salePricePerUnit: e.salePricePerUnit?.toString() || "0",
            salePrice: e.salePrice?.toString() || "0",
            rentPricePerUnit: e.rentPricePerUnit?.toString() || "0",
            rentPrice: e.rentPrice?.toString() || "0",
            landWidth: e.landWidth?.toString() || "0",
            landDepth: e.landDepth?.toString() || "0",
            landArea: e.landArea.toString() || "",
            builtUpWidth: e.builtUpWidth?.toString() || "0",
            builtUpDepth: e.builtUpDepth?.toString() || "0",
            builtUpArea: e.builtUpArea.toString() || "0",
            numberOfBedroom: parseInt(e.numberOfBedroom) || 0,
            numberOfBathroom: parseInt(e.numberOfBathroom) || 0,
            mainPhoto: e.mainPhoto || [],
            gallery: e.gallery || [],
            renovationCost: e.renovationCost?.toString() || "0",
            renovationDetail: e.renovationDetail || "",
            description: e.description || "",
            latitude: convNull(e.latitude?.toString()) || "",
            longitude: convNull(e.longitude?.toString()) || "",
            videoLink: e.videoLink ? getFullAddress(e.videoLink) : "",
            vrLink: e.vrLink ? getFullAddress(e.vrLink) : "",
            status: e.status || "",
            allowPartnership: e.allowPartnership || false,
            partnershipRemark: e.partnershipRemark || "",
            partnershipJobScope: e.partnershipJobScope || "",
            remark: e.remark || "",
            propertyOwner: {
                name: e.propertyOwner.name || "",
                contact: e.propertyOwner.contact || "",
                remark: e.propertyOwner.remark || "",
                email: e.propertyOwner.email || ""
            },
            propertyCommission: {
                salesCommissionType:
                    e.propertyCommission.salesCommissionType || "",
                salesCommissionValue:
                    e.propertyCommission.salesCommissionValue || "",
                rentCommissionAmount:
                    e.propertyCommission.rentCommissionAmount || ""
            },
            propertyFacilities: e.propertyFacilities.map((e) => {
                return {
                    id: e.id
                };
            }),
            propertyAmenities: e.propertyAmenities.map((e) => {
                return {
                    id: e.id
                };
            }),
            hashtag: e.hashtag || "",
            offerToPurchase: subsalesOTPModel.putPayload(e.offerToPurchase),
            offerToRent: rentOTRModel.putPayload(e.offerToRent),
            ownerPropertyCode: e.ownerPropertyCode || "",

            // Auction
            auctionTime: e.auctionTime || "",
            auctionDate: e.auctionDate || "",
            auctionVenue: e.auctionVenue || "",
            auctionTypeId: e.auctionTypeId || "",
            assignee: e.assignee || "",
            auctioneer: e.auctioneer || "",
            lawyer: e.lawyer || "",
            assignor: e.assignor || "",
            deposit: e.deposit || "0",
            proclamationOfSale: e.proclamationOfSale || []
        };
    }

    static getToEditResponse(e) {
        return {
            id: e.id,
            agencyUserId: e.agencyUser?.id || "",
            agencyUser: e.agencyUser || {},
            countryId: e.country.id || "",
            stateId: e.state.id || "",
            cityId: e.city.id || "",
            areaId: e.area.id || "",
            street: e.street || "",
            buildingId: e.building ? e.building.id : "",

            country: e.country || "",
            state: e.state || "",
            city: e.city || "",
            area: e.area || "",
            building: e.building || "",

            lotNumber: e.lotNumber || "",
            title: e.title || "",
            propertyCategory: e.propertyCategory || "",
            propertyTypeId: e.propertyType.id || "",
            lotTypeId: e.lotType ? e.lotType.id : "",
            tenureType: e.tenureType || [],
            furnishingType: e.furnishingType || "",
            purpose: e.purpose || "",
            areaUnitType: e.areaUnitType || "",
            salePricePerUnit: e.salePricePerUnit || "",
            salePrice: e.salePrice || "",
            rentPricePerUnit: e.rentPricePerUnit || "",
            rentPrice: e.rentPrice || "",
            landWidth: e.landWidth || "",
            landDepth: e.landDepth || "",
            landArea: e.landArea || "",
            builtUpWidth: e.builtUpWidth || "",
            builtUpDepth: e.builtUpDepth || "",
            builtUpArea: e.builtUpArea || "",
            numberOfBedroom: e.numberOfBedroom || "",
            numberOfBathroom: e.numberOfBathroom || "",
            mainPhoto: e.mainPhoto || [],
            gallery: e.gallery || [],
            renovationCost: e.renovationCost || "",
            renovationDetail: e.renovationDetail || "",
            description: e.description || "",
            latitude: e.latitude || "",
            longitude: e.longitude || "",
            videoLink: e.videoLink || "",
            vrLink: e.vrLink || "",
            status: e.status || "",
            allowPartnership: e.allowPartnership ?? "",
            partnershipRemark: e.partnershipRemark || "",
            partnershipJobScope: e.partnershipJobScope || "",
            remark: e.remark || "",
            propertyOwner: {
                name: e.owner.name || "",
                contact: e.owner.contact || "",
                remark: e.owner.remark || "",
                email: e.owner.email || ""
            },

            propertyCommission: e.commission || {},

            propertyFacilities: e.propertyFacilities || [],
            propertyAmenities: e.propertyAmenities || [],
            propertyCustomCategories: e.propertyCustomCategories || [],
            propertyTags:
                e.hashtag === ""
                    ? []
                    : e.hashtag.split(",").map((tag) => ({ text: tag })),
            hashtag: e.hashtag || "",
            offerToPurchase: subsalesOTPModel.getToEdit(e.offerToPurchase),
            offerToRent: rentOTRModel.getToEdit(e.offerToRent),

            ownerPropertyCode: e.ownerPropertyCode
                ? e.ownerPropertyCode.code
                : "",
            ownerPropertyCodeConfirm: e.ownerPropertyCodeConfirm,

            // Auction
            auctionTime: e.propertyAuction?.auctionTime || "",
            auctionDate: e.propertyAuction?.auctionDate || "",
            auctionVenue: e.propertyAuction?.auctionVenue || "",
            auctionTypeId: e.propertyAuction?.auctionType.id || "",
            assignee: e.propertyAuction?.assignee || "",
            auctioneer: e.propertyAuction?.auctioneer || "",
            lawyer: e.propertyAuction?.lawyer || "",
            assignor: e.propertyAuction?.assignor || "",
            deposit: convNull(e.propertyAuction?.deposit) || "",
            proclamationOfSale: e.propertyAuction?.proclamationOfSale || []
        };
    }
}

export default PropertyModel;
