import { convertNullIfEmpty } from "@/utils/null-check";

class AgencyUserModel {
    static postPayload(e) {
        return {
            branchId: e.branchId || "",
            roleId: e.roleId || "",

            countryId: e.countryId || "",
            stateId: e.stateId || "",
            cityId: e.cityId || "",
            areaId: e.areaId || "",
            address: e.address || "",

            name: e.name || "",
            email: e.email || "",
            password: e.password || "",
            avatar: e.avatar || [],
            actualName: e.actualName || "",
            birthdate: e.birthdate || "",
            introduction: e.introduction || "",
            experience: e.experience || "",
            renLicense: e.renLicense || "",
            gender: e.gender || "",
            bankAccountNumber: e.bankAccountNumber || "",
            bankAccountFullName: e.bankAccountFullName || "",
            bank: e.bank || "",

            mobileISOCode: convertNullIfEmpty(e.phoneNo.mobileISOCode) || "",
            mobileCountryCode:
                convertNullIfEmpty(e.phoneNo.mobileCountryCode) || "",
            mobileNumber: convertNullIfEmpty(e.phoneNo.mobileNumber) || ""
        };
    }

    static getToEditResponse(e) {
        return {
            branchId: e.agencyBranch.id || "",
            roleId: e.rolePermission.id || "",

            country: e.country || "",
            state: e.state || "",
            city: e.city || "",
            area: e.area || "",

            countryId: e.country.id || "",
            stateId: e.state.id || "",
            cityId: e.city.id || "",
            areaId: e.area.id || "",
            address: convertNullIfEmpty(e.address) || "",

            name: e.name || "",
            email: e.user.email || "",
            avatar: e.avatar || [],
            actualName: e.actualName || "",
            birthdate: convertNullIfEmpty(e.user?.birthdate) || "",
            introduction: convertNullIfEmpty(e.introduction) || "",
            experience: convertNullIfEmpty(e.experience) || "",
            renLicense: convertNullIfEmpty(e.renLicense) || "",
            gender: e.user?.gender || "",
            bankAccountNumber: convertNullIfEmpty(e.bankAccountNumber) || "",
            bankAccountFullName:
                convertNullIfEmpty(e.bankAccountFullName) || "",
            bank: convertNullIfEmpty(e.bank) || "",

            mobileISOCode: e.user.mobileISOCode || "",
            mobileCountryCode: e.user.mobileCountryCode || "",
            mobileNumber: e.user.mobileNumber || ""
        };
    }
}

export default AgencyUserModel;
