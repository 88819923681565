import omit from "lodash/omit";
import cloneDeep from "lodash/cloneDeep";
import { convertNullIfEmpty as convNull } from "@/utils/null-check";

let generalDetail = {
    id: "",
    authorizationFiles: [],
    companyName: "",
    companyNo: "",
    consumerType: "",
    otherDocuments: [],
    ssmFiles: [],
    relationshipFiles: []
};

let userDetail = {
    id: "",
    name: "",
    icPassportType: "",
    icPassport: "",
    icBackPhoto: [],
    icFrontPhoto: [],
    passportPhoto: [],
    nationality: "",
    contact: "",
    relationshipId: "",
    address: "",
    isFirstPerson: false,
    isDelete: false
};

let landlordDetail = {
    bank: "",
    accountName: "",
    accountNumber: "",
    ...generalDetail,
    users: [userDetail]
};

let tenantDetail = {
    ...generalDetail,
    users: [userDetail]
};

class rentTAModel {
    static getToEditTAApplicant(e) {
        return {
            ...omit(e?.tenantDetail, ["users"]),
            users:
                e?.tenantDetail?.users?.map((tenant) => ({
                    ...tenant,
                    relationshipId: tenant.relationship?.id || "",
                    isDelete: false
                })) || cloneDeep(userDetail)
        };
    }
    static getToEditTAOwner(e) {
        return {
            ...omit(e?.landLordDetail, ["users"]),
            users:
                e?.landLordDetail?.users?.map((landlord) => ({
                    ...landlord,
                    relationshipId: landlord.relationship?.id || "",
                    isDelete: false
                })) || cloneDeep(userDetail)
        };
    }

    static getToEditTA(e) {
        return {
            agreementDate: e?.agreementDate || "",
            terms: e?.terms || "",
            commencementDate: e?.commencementDate || "",
            dateOfExpiry: e?.dateOfExpiry || "",
            rentFreePeriodFrom: e?.rentFreePeriodFrom || "",
            rentFreePeriodTo: e?.rentFreePeriodTo || "",
            monthlyRent: e?.monthlyRent || "",
            securityDeposit: e?.securityDeposit || "",
            utilitiesDeposit: e?.utilitiesDeposit || "",
            otherDeposit: convNull(e?.otherDeposit) || "",
            remark: e?.remark || "",
            taxRate: e?.taxRate || "",
            taxAmount: e?.taxAmount || "",
            purpose: e?.purpose || "",
            furtherTerm: e?.furtherTerm || "",
            rentalFurtherTerm: e?.rentalFurtherTerm || "",
            offerToLeaseType: e?.offerToLeaseType || "",
            offerToLeaseDocuments: e?.offerToLeaseDocuments || [],
            specialConditions: e?.specialConditions || ""
        };
    }

    static getToEditArchiveRent(e) {
        return {
            tenantInfo: this.getToEditTAApplicant(e),
            landlordInfo: this.getToEditTAOwner(e),
            tenancyAgreement: this.getToEditTA(e)
        };
    }

    static mapUserGeneralData(e) {
        return {
            authorizationFiles: e?.authorizationFiles || [],
            companyName: e?.companyName || "",
            companyNo: e?.companyNo || "",
            consumerType: e?.consumerType || "",
            otherDocuments: e?.otherDocuments || [],
            relationshipFiles: e?.relationshipFiles || [],
            ssmFiles: e?.ssmFiles || []
        };
    }

    static mapLandlordBankAccount(e) {
        return {
            bank: e.bank || "",
            accountName: e.accountName || "",
            accountNumber: e.accountNumber || ""
        };
    }

    static mapTAUser(e) {
        return e?.users.map((user) => {
            let mapped = {};
            Object.keys(userDetail).map((key) => {
                mapped[key] = user[key] || userDetail[key];
            });
            return mapped;
        });
    }

    static toUpdateTAApplicant(e) {
        return {
            ...this.mapUserGeneralData(e),
            users: this.mapTAUser(e)
        };
    }

    static toUpdateTAOwner(e) {
        return {
            ...this.mapUserGeneralData(e),
            ...this.mapLandlordBankAccount(e),
            users: this.mapTAUser(e)
        };
    }

    static toUpdateTAGeneral(e) {
        return {
            agreementDate: e?.agreementDate || "",
            terms: e?.terms || "",
            commencementDate: e?.commencementDate || "",
            dateOfExpiry: e?.dateOfExpiry || "",
            monthlyRent: e?.monthlyRent || "",
            securityDeposit: e?.securityDeposit || "",
            utilitiesDeposit: e?.utilitiesDeposit || "",
            otherDeposit: e?.otherDeposit || "",
            remark: e?.remark || "",
            rentFreePeriodFrom: e?.rentFreePeriodFrom || "",
            rentFreePeriodTo: e?.rentFreePeriodTo || "",
            purpose: e?.purpose || "",
            furtherTerm: e?.furtherTerm || "",
            rentalFurtherTerm: e?.rentalFurtherTerm || "",
            offerToLeaseType: e?.offerToLeaseType || "",
            offerToLeaseDocuments: e?.offerToLeaseDocuments || [],
            specialConditions: e?.specialConditions || "",
            taxRate: e?.taxRate || "",
            taxAmount: e?.taxAmount || ""
        };
    }

    static toUpdateTA(e) {
        return {
            tenancyAgreement: this.toUpdateTAGeneral(e.tenancyAgreement),
            tenantInfo: this.toUpdateTAApplicant(e?.tenantInfo),
            landlordInfo: this.toUpdateTAOwner(e?.landlordInfo)
        };
    }

    static toCreateArchiveRent(e) {
        return {
            propertyId: e?.propertyId || "",
            tenancyAgreement: this.toUpdateTAGeneral(e.tenancyAgreement),
            tenantInfo: this.toUpdateTAApplicant(e?.tenantInfo),
            landlordInfo: this.toUpdateTAOwner(e?.landlordInfo)
        };
    }
    static toUpdateArchiveRent(e) {
        return {
            tenancyAgreement: this.toUpdateTAGeneral(e.tenancyAgreement),
            tenantInfo: this.toUpdateTAApplicant(e?.tenantInfo),
            landlordInfo: this.toUpdateTAOwner(e?.landlordInfo)
        };
    }
}

export default rentTAModel;
export { landlordDetail, userDetail, tenantDetail };
