import moment from "moment";

class ProjectReservationsModel {
    static postPrivateReservationPayload(e) {
        return {
            buyerId: e.buyerId || "",
            purchaserName1: e.purchaserName1 || "",
            purchaserIdentityCardOrPassport1:
                e.purchaserIdentityCardOrPassport1 || "",
            gender1: e.gender1 || "",
            nationality1: e.nationality1 || "",
            purchaserName2: e.purchaserName2 || "",
            purchaserIdentityCardOrPassport2:
                e.purchaserIdentityCardOrPassport2 || "",
            gender2: e.gender2 || "",
            nationality2: e.nationality2 || "",
            nonBumiOrForeigner: e.nonBumiOrForeigner || "",
            mailingAddress: e.mailingAddress || "",
            phoneContact: e.phoneContact || "",
            officeContact: e.officeContact || "",
            faxContact: e.faxContact || "",
            email: e.email || ""
        };
    }

    static postApprovePayload(e) {
        return {
            paymentDueDate: moment(e.paymentDueDate).format("YYYY-MM-DD")
        };
    }

    static getToEditPrivateReservation(e) {
        return {
            buyerId: e.buyer.id || "",
            purchaserName1: e.purchaserName1 || "",
            purchaserIdentityCardOrPassport1:
                e.purchaserIdentityCardOrPassport1 || "",
            gender1: e.gender1 || "",
            nationality1: e.nationality1 || "",
            purchaserName2: e.purchaserName2 || "",
            purchaserIdentityCardOrPassport2:
                e.purchaserIdentityCardOrPassport2 || "",
            gender2: e.gender2 || "",
            nationality2: e.nationality2 || "",
            nonBumiOrForeigner: e.nonBumiOrForeigner || "",
            mailingAddress: e.mailingAddress || "",
            phoneContact: e.phoneContact || "",
            officeContact: e.officeContact || "",
            faxContact: e.faxContact || "",
            email: e.user.email || ""
        };
    }
}

export default ProjectReservationsModel;
