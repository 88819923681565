import uniqBy from "lodash/uniqBy";
class ProjectAgentAllocationModel {
    static postPayload(e) {
        let uniqAgents = uniqBy(e, "id");
        return {
            agents: uniqAgents.map((agent) => ({
                id: agent.id,
                name: agent.name,
                canChat: agent.canChat
            }))
        };
    }
    static putPayload(e) {
        let uniqAgents = uniqBy(e, "id");
        return {
            agents: uniqAgents.map((row) => ({
                id: row.agent.id,
                name: row.agent.name,
                canChat: row.canChat
            }))
        };
    }
}

export default ProjectAgentAllocationModel;
