class ProjectBooking {
    static postCancelRejectPayload(e) {
        return {
            description: e.description || ""
        };
    }

    static postApprovePayload(e) {
        return {
            salesPurchaseAgreementDueDate: e.salesPurchaseAgreementDueDate || ""
        };
    }
}

export default ProjectBooking;
