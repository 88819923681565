class ProjectRefundModel {
    static putApprovePayload(e) {
        return {
            refundDeposit: e.refundDeposit.toString() || "0.00",
            refundReceipt: e.refundReceipt || [],
            remark: e.remark || ""
        };
    }

    static putRejectPayload(e) {
        return {
            message: e.description || ""
        };
    }
}

export default ProjectRefundModel;
