class AccountModel {
    static postPayload(e) {
        return {
            introduction: e.introduction || "",
            experience: e.experience || ""
        };
    }

    static getToEditResponse(e) {
        return {
            introduction: e.introduction || "",
            experience: e.experience || ""
        };
    }
}

export default AccountModel;
