class RolePermissionModel {
    static postPayload(e) {
        return {
            name: e.name || "",
            description: e.description || "",
            isAgencyAdmin: e.isAgencyAdmin || false,
            isBranchAdmin: e.isBranchAdmin || false,
            permission: e.permission || {}
        };
    }
}

export default RolePermissionModel;
